<template>
  <div class="container">
    <!-- <div class="header">
      <van-image round width="2rem" height="2rem" :src="headPortrait" />
    </div>
    <div class="title">{{ petName }}</div>
    <div class="subtitle">申请获取以下权限</div>
    <div class="tip">获取你的公开信息(昵称，头像等)</div>
    <div class="btn">
      <van-button type="primary" round style="width: 9rem" @click="oauth"
        >授权登录</van-button
      >
    </div> -->
  </div>
</template>

<script>
import { getUserAPI } from "@/api/index";
import website from "@/config/website";
export default {
  data() {
    return {
      headPortrait: "",
      petName: "",
      code: "",
      tenantId:'',
      appCode:''
    };
  },
  created() {
    let setUrl = window.parent.location.href
    let urlStr = setUrl.split('?')[1]
	  const urlSearchParams = new URLSearchParams(urlStr)
	  const result = Object.fromEntries(urlSearchParams.entries())
    console.log(result,"result");  
    this.tenantId = result.state;
    this.code = result.code;

    // this.appCode = (new URLSearchParams(setUrl.split("?")[2])).get('appCode');
    this.getUserInfo();
    
  },
  methods: {
    //获取用户信息
    getUserInfo() {
      getUserAPI(website.corp_id, this.code).then((res) => {
        console.log(res.data.data, "userInfo");
        this.headPortrait = res.data.data.avatar;
        this.petName = res.data.data.name;
        this.mobile = res.data.data.mobile;
        localStorage.setItem("headPortrait", this.headPortrait);
        localStorage.setItem("petName", this.petName);
        localStorage.setItem("mobile", this.mobile);
        localStorage.setItem("tenantId", this.tenantId);
        localStorage.setItem("appCode", this.appCode);
        //  this.$router.push({ path: "/selectHospital" });
        this.$router.push({ path: '/bindingOfMobile', query: { tenantId:this.tenantId } }); 
      });
    },
   
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 100px;
  position: relative;
}
.van-image {
  margin: 2rem 4rem;
}
.title {
  position: absolute;
  top: 5rem;
  width: 100%;
  text-align: center;
  font-size: 0.4rem;
}
.subtitle {
  position: absolute;
  top: 7rem;
  left: 1rem;
  font-size: 0.4rem;
}
.tip {
  position: absolute;
  top: 8rem;
  left: 1rem;
  font-size: 0.4rem;
  color: #9d9d9d;
}
.btn {
  position: absolute;
  top: 10rem;
  left: 0.45rem;
}
</style>